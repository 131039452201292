import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useApp } from '../../hooks/AppContext';
var NewsTabs = function (_a) {
    var selectedType = _a.selectedType, handleTypeChange = _a.handleTypeChange, lang = _a.lang, getNewsColorSelected = _a.getNewsColorSelected;
    var topics = {
        es: ["criptomonedas", "actualidad", "deportes", "cine"],
        en: ["cryptocurrency", "current affairs", "sports", "cinema"],
        fr: ["cryptomonnaie", "actualité", "sports", "cinéma"],
    };
    var translate = useApp().translate;
    return (React.createElement(Tabs, { value: selectedType, onChange: handleTypeChange, indicatorColor: "primary", textColor: "primary", centered: true, sx: {
            marginBottom: '20px',
            '& .MuiTabs-indicator': {
                backgroundColor: "".concat(getNewsColorSelected(), " !important"), // Cambia dinámicamente el color de la línea de subrayado
            },
            '& .Mui-selected': {
                color: "".concat(getNewsColorSelected(), " !important"), // Forzar prioridad
            },
            '& .MuiTab-root': {
                color: 'gray', // Color de las pestañas no seleccionadas
            },
        } },
        React.createElement(Tab, { label: translate('news.allTab'), value: "all" }),
        topics[lang].map(function (topic) { return (React.createElement(Tab, { key: topic, label: topic.charAt(0).toUpperCase() + topic.slice(1), value: topic })); })));
};
export default NewsTabs;
