import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, Box, Link, } from '@mui/material';
import { useApp } from '../../hooks/AppContext';
// Componente para mostrar anuncios de Google
var GoogleAd = function (_a) {
    var adSlot = _a.adSlot;
    return (React.createElement(Box, { sx: { margin: '20px 0', textAlign: 'center' } },
        React.createElement("ins", { className: "adsbygoogle", style: { display: 'block' }, "data-ad-client": "ca-pub-9439772442385398", "data-ad-slot": adSlot, "data-ad-format": "auto" }),
        React.createElement("script", null,
            "(adsbygoogle = window.adsbygoogle || []).push(",
            ");")));
};
var CryptoNews = function (_a) {
    var news = _a.news, getNewsColorSelected = _a.getNewsColorSelected;
    var translate = useApp().translate;
    return (React.createElement("div", { style: { padding: '10px', backgroundColor: '#f9f9f9' } },
        React.createElement(Grid, { container: true, spacing: 3 }, news.map(function (article, index) { return (React.createElement(React.Fragment, { key: index },
            React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 4 },
                React.createElement(Card, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        borderRadius: '12px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
                        },
                    } },
                    article.image_url && (React.createElement(CardMedia, { component: "img", height: "180", image: article.image_url, alt: article.title, sx: { borderRadius: '12px 12px 0 0', objectFit: 'cover' } })),
                    React.createElement(CardContent, { sx: {
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        } },
                        React.createElement(Link, { href: article.url, target: "_blank", rel: "noopener noreferrer", underline: "hover", sx: {
                                fontWeight: 'bold',
                                fontSize: '1.2rem',
                                color: getNewsColorSelected(),
                                marginBottom: '10px',
                                transition: 'color 0.2s ease',
                                '&:hover': { color: '#0d47a1' },
                            } }, article.title),
                        React.createElement(Typography, { variant: "body2", color: "text.secondary", sx: { marginBottom: '10px', lineHeight: '1.6' } }, article.description),
                        React.createElement(Typography, { variant: "caption", color: "text.secondary", sx: { marginTop: 'auto' } },
                            "Fuente: ",
                            article.source_name,
                            " |",
                            ' ',
                            new Date(article.published_at).toLocaleString())))),
            index % 3 === 2 && (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(GoogleAd, { adSlot: "crypto_news_between" }))))); }))));
};
export default CryptoNews;
