import React from 'react';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Newspaper from './pages/news/Newspaper';
import { useApp } from './hooks/AppContext';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
var App = function () {
    var currentView = useApp().currentView;
    return (React.createElement(React.Fragment, null,
        currentView === '' && React.createElement(Home, null),
        currentView === 'about' && React.createElement(About, null),
        currentView === 'news' && React.createElement(Newspaper, null)));
};
export default App;
