var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useApp } from '../../hooks/AppContext';
import { Typography, Box, Container, CircularProgress } from '@mui/material';
import NavBar from '../../components/navBar/NavBar';
import GoogleAd from './GoogleAd';
import CryptoNews from './News';
import { fetchNews } from './newsManager';
import NewsTabs from './NewsTabs';
var Newspaper = function () {
    var _a = useState([]), news = _a[0], setNews = _a[1];
    var _b = useState([]), filteredNews = _b[0], setFilteredNews = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState('all'), selectedType = _d[0], setSelectedType = _d[1];
    var _e = useApp(), currentLanguage = _e.currentLanguage, translate = _e.translate;
    useEffect(function () {
        var getNews = function () { return __awaiter(void 0, void 0, void 0, function () {
            var uniqueNews, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fetchNews(currentLanguage)];
                    case 1:
                        uniqueNews = _a.sent();
                        setNews(uniqueNews);
                        setFilteredNews(uniqueNews);
                        setLoading(false);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching news:', error_1);
                        setLoading(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        getNews();
    }, [currentLanguage]);
    var handleTypeChange = function (event, newValue) {
        setSelectedType(newValue);
        if (newValue === 'all') {
            setFilteredNews(news.filter(function (article) { return article.lang === currentLanguage; }));
        }
        else {
            setFilteredNews(news.filter(function (article) { return article.type === newValue && article.lang === currentLanguage; }));
        }
    };
    var getNewsColorSelected = function () {
        switch (selectedType) {
            case 'criptomonedas':
            case 'cryptocurrency':
            case 'cryptomonnaie':
                return '#28A745';
            case 'sports':
            case 'deportes':
                return '#DC3545';
            case 'actualidad':
            case 'current affairs':
            case 'actualité':
                return '#b833ff';
            case 'cine':
            case 'cinema':
            case 'cinéma':
                return '#ff7a33';
            default:
                return '#1976d2';
        }
    };
    var getTitle = function () {
        switch (selectedType) {
            case 'criptomonedas':
            case 'cryptocurrency':
            case 'cryptomonnaie':
                return translate('news.cryptoTitle');
            case 'sports':
            case 'deportes':
                return translate('news.sportsTitle');
            case 'actualidad':
            case 'current affairs':
            case 'actualité':
                return translate('news.breakingNewsTitle');
            case 'cine':
            case 'cinema':
            case 'cinéma':
                return translate('news.cinemaTitle');
            default:
                return translate('news.title');
        }
    };
    var getSubTitle = function () {
        switch (selectedType) {
            case 'criptomonedas':
            case 'cryptocurrency':
            case 'cryptomonnaie':
                return translate('news.cryptoSubTitle');
            case 'sports':
            case 'deportes':
                return translate('news.sportsSubTitle');
            case 'actualidad':
            case 'current affairs':
            case 'actualité':
                return translate('news.breakingNewsSubTitle');
            case 'cine':
            case 'cinema':
            case 'cinéma':
                return translate('news.cinemaSubTitle');
            default:
                return translate('news.allSubTitle');
        }
    };
    return (React.createElement(Box, { sx: { backgroundColor: '#f9f9f9', minHeight: '100vh' } },
        React.createElement(NavBar, null),
        React.createElement(Box, { sx: {
                backgroundColor: getNewsColorSelected(),
                color: 'white',
                padding: '20px 0',
                textAlign: 'center',
                marginBottom: '30px',
            } },
            React.createElement(Typography, { variant: "h3", component: "h1", sx: { fontWeight: 'bold' } }, getTitle()),
            React.createElement(Typography, { variant: "subtitle1", sx: { marginTop: '10px', fontSize: '1.2rem' } }, getSubTitle())),
        React.createElement(Container, { maxWidth: "lg" },
            React.createElement(Box, { sx: { marginBottom: '30px', textAlign: 'center' } },
                React.createElement(GoogleAd, { adSlot: "header" })),
            React.createElement(NewsTabs, { selectedType: selectedType, handleTypeChange: handleTypeChange, lang: currentLanguage, getNewsColorSelected: getNewsColorSelected }),
            loading ? (React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "300px" },
                React.createElement(CircularProgress, null))) : (React.createElement(CryptoNews, { news: filteredNews, getNewsColorSelected: getNewsColorSelected })))));
};
export default Newspaper;
